import Simple from '@/components/task-templates/Simple'

export default [
  {
    component: Simple,
    name: 'Вывод range',
    text: 'Дан range \\(r\\). Выведите все его элементы через пробел',
    note: 'Для вывода range в виде чисел через пробел перед range ставится *, например: print(*range(3))',
    input: '\\(r\\) - range',
    output: 'Числа через пробел',
    examples: [
      {
        input: 'range(3)',
        output: '0 1 2'
      },
      {
        input: 'range(1, 4)',
        output: '1 2 3'
      },
      {
        input: 'range(2, 10, 3)',
        output: '2 5 8'
      },
      {
        input: 'range(10, 6, -1)',
        output: '10 9 8 7'
      }
    ]
  },
  {
    component: Simple,
    name: '0..4',
    text: 'Ничего не дано. Выведите числа от 0 до 4 включительно, используя range',
    note: null,
    input: 'Ничего',
    output: 'Числа через пробел',
    examples: [
      {
        input: '',
        output: '0 1 2 3 4'
      }
    ]
  },
  {
    component: Simple,
    name: '1..5',
    text: 'Ничего не дано. Выведите числа от 1 до 5 включительно, используя range',
    note: null,
    input: 'Ничего',
    output: 'Числа через пробел',
    examples: [
      {
        input: '',
        output: '1 2 3 4 5'
      }
    ]
  },
  {
    component: Simple,
    name: '0..10 с шагом 2',
    text: 'Ничего не дано. Выведите числа от 0 до 10 включительно с шагом 2, используя range',
    note: null,
    input: 'Ничего',
    output: 'Числа через пробел',
    examples: [
      {
        input: '',
        output: '0 2 4 6 8 10'
      }
    ]
  },
  {
    component: Simple,
    name: '1..11 с шагом 2',
    text: 'Ничего не дано. Выведите числа от 1 до 11 включительно с шагом 2, используя range',
    note: null,
    input: 'Ничего',
    output: 'Числа через пробел',
    examples: [
      {
        input: '',
        output: '1 3 5 7 9 11'
      }
    ]
  },
  {
    component: Simple,
    name: '5..1',
    text: 'Ничего не дано. Выведите числа от 5 до 1 включительно, используя range',
    note: null,
    input: 'Ничего',
    output: 'Числа через пробел',
    examples: [
      {
        input: '',
        output: '5 4 3 2 1'
      }
    ]
  },
  {
    component: Simple,
    name: '4..0',
    text: 'Ничего не дано. Выведите числа от 4 до 0 включительно, используя range',
    note: null,
    input: 'Ничего',
    output: 'Числа через пробел',
    examples: [
      {
        input: '',
        output: '4 3 2 1 0'
      }
    ]
  },
  {
    component: Simple,
    name: '11..1 с шагом 2',
    text: 'Ничего не дано. Выведите числа от 11 до 1 включительно с шагом 2, используя range',
    note: null,
    input: 'Ничего',
    output: 'Числа через пробел',
    examples: [
      {
        input: '',
        output: '11 9 7 5 3 1'
      }
    ]
  },
  {
    component: Simple,
    name: '10..0 с шагом 2',
    text: 'Ничего не дано. Выведите числа от 10 до 0 включительно с шагом 2, используя range',
    note: null,
    input: 'Ничего',
    output: 'Числа через пробел',
    examples: [
      {
        input: '',
        output: '10 8 6 4 2 0'
      }
    ]
  },
  {
    component: Simple,
    name: '0..n-1',
    text: 'Дано число \\(n\\). Выведите числа от 0 включительно до \\(n\\) невключительно',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '0 1 2 3'
      },
      {
        input: '6',
        output: '0 1 2 3 4 5'
      }
    ]
  },
  {
    component: Simple,
    name: '1..n',
    text: 'Дано число \\(n\\). Выведите числа от 1 до \\(n\\) включительно',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '1 2 3 4'
      },
      {
        input: '6',
        output: '1 2 3 4 5 6'
      }
    ]
  },
  {
    component: Simple,
    name: 'n..1',
    text: 'Дано число \\(n\\). Выведите числа от \\(n\\) до 1 включительно',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '4 3 2 1'
      },
      {
        input: '6',
        output: '6 5 4 3 2 1'
      }
    ]
  },
  {
    component: Simple,
    name: 'n-1..0',
    text: 'Дано число \\(n\\). Выведите числа от \\(n\\) не включительно до 0 включительно',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '3 2 1 0'
      },
      {
        input: '6',
        output: '5 4 3 2 1 0'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётные от 0 до n',
    text: 'Дано число \\(n\\). Выведите все чётные числа от 0 до \\(n\\) включительно',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '0 2 4'
      },
      {
        input: '7',
        output: '0 2 4 6'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первые n чётных',
    text: 'Дано число \\(n\\). Выведите первые \\(n\\) чётных чисел, начиная с 2',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '2 4 6 8'
      },
      {
        input: '6',
        output: '2 4 6 8 10 12'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первые n нечётных',
    text: 'Дано число \\(n\\). Выведите первые \\(n\\) нечётных чисел, начиная с 1',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '1 3 5 7'
      },
      {
        input: '6',
        output: '1 3 5 7 9 11'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первые n чётных в обратном порядке',
    text: 'Дано число \\(n\\). Выведите первые \\(n\\) чётных чисел от 2 в обратном порядке',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '8 6 4 2'
      },
      {
        input: '6',
        output: '12 10 8 6 4 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первые n нечётных в обратном порядке',
    text: 'Дано число \\(n\\). Выведите первые \\(n\\) нечётных чисел с 1 в обратном порядке',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '7 5 3 1'
      },
      {
        input: '6',
        output: '11 9 7 5 3 1'
      }
    ]
  },
  {
    component: Simple,
    name: 'n десятков',
    text: 'Дано число \\(n\\). Выведите 10, 20, 30... \\(n * 10\\)',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '10 20 30 40'
      },
      {
        input: '6',
        output: '10 20 30 40 50 60'
      }
    ]
  },
  {
    component: Simple,
    name: 'n десятков в обратном порядке',
    text: 'Дано число \\(n\\). Выведите \\(n * 10\\)... 30, 20, 10',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '40 30 20 10'
      },
      {
        input: '6',
        output: '60 50 40 30 20 10'
      }
    ]
  },
  {
    component: Simple,
    name: 'n отрицательных десятков',
    text: 'Дано число \\(n\\). Выведите -10, -20, -30... \\(-n * 10\\)',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '-10 -20 -30 -40'
      },
      {
        input: '6',
        output: '-10 -20 -30 -40 -50 -60'
      }
    ]
  },
  {
    component: Simple,
    name: 'n отрицательных десятков наоборот',
    text: 'Дано число \\(n\\). Выведите \\(-n * 10\\)... -30, -20, -10',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '4',
        output: '-40 -30 -20 -10'
      },
      {
        input: '6',
        output: '-60 -50 -40 -30 -20 -10'
      }
    ]
  },
  {
    component: Simple,
    name: 'Чётные числа меньше n наоборот',
    text: 'Дано число \\(n\\). Выведите все чётные числа от 2 до \\(n\\) включительно в порядке убывания',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '8',
        output: '8 6 4 2'
      },
      {
        input: '7',
        output: '6 4 2'
      }
    ]
  },
  {
    component: Simple,
    name: 'Нечётные числа от n по убыванию',
    text: 'Дано число \\(n\\). Выведите все нечётные числа от 1 до \\(n\\) включительно в порядке убывания',
    note: null,
    input: '\\(n\\) - целое положительное число',
    output: 'Числа через пробел',
    examples: [
      {
        input: '6',
        output: '5 3 1'
      },
      {
        input: '7',
        output: '7 5 3 1'
      }
    ]
  },
  {
    component: Simple,
    name: 'Первые три числа из range',
    text: 'Дан range \\(r\\). Выведите первые три его элемента через пробел, используя обращения по индексам',
    note: 'Для обращения по индексам используются квадратные скобки, при этом элементы, как правило, индексируются с нуля. Это значит, что если хотим обратиться к первому элементу, то в квадратных скобках нужно писать 0, ко второму - 1 и т. д. Например: r[0] - первый элемент, r[1] - второй',
    input: '\\(r\\) - range',
    output: 'Числа через пробел',
    examples: [
      {
        input: 'range(10, 30, 5)',
        output: '10 15 20'
      },
      {
        input: 'range(-1, -10, -1)',
        output: '-1 -2 -3'
      }
    ]
  },
  {
    component: Simple,
    name: 'Последние три числа из range',
    text: 'Дан range \\(r\\). Выведите последние три его элемента через пробел, используя обращения по индексам',
    note: 'Чтобы обращаться к элементам с конца, используются отрицательные индексы. Например, r[-1] - последний (первый с конца), r[-2] - второй с конца и т. д.',
    input: '\\(r\\) - range',
    output: 'Числа через пробел',
    examples: [
      {
        input: 'range(10, 30, 5)',
        output: '15 20 25'
      },
      {
        input: 'range(-1, -10, -1)',
        output: '-7 -8 -9'
      }
    ]
  },
  {
    component: Simple,
    name: 'Длина диапазона',
    text: 'Дан range \\(r\\). Выведите его длину',
    note: 'Чтобы узнать длину, можно воспользоваться встроенной функцией len. Например: len(range(3, 7)) - будет равно 4',
    input: '\\(r\\) - range',
    output: 'Числа через пробел',
    examples: [
      {
        input: 'range(10, 30, 5)',
        output: '4'
      },
      {
        input: 'range(-1, -10, -1)',
        output: '9'
      }
    ]
  }
]
